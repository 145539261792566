import React, { useState } from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import { useInView } from "react-intersection-observer";
import ZoomSlider from "../../components/zoomImageSlider";
import YouTubeVideo from "./YouTubeVideo";
import PilgrimageSlider from "./pilgrimage-slider";

export default function PilgrimageWasteManagement() {
  const zoomImages = [
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/20170810_080654.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/20190511_092414.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/20190511_092635.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/20190511_144201.jpg",
  ];

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const paragraphStyles1 = {
    WebkitLineClamp: 7,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  const paragraphStyles2 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  const paragraphStyles3 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  const paragraphStyles4 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles5 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // animation
  const [session5, session5InView] = useInView({
    triggerOnce: true,
  });

  const [textboxRef, textInView] = useInView({
    triggerOnce: true,
  });

  const videoUrl = "https://youtu.be/xSILvDCyFfw?si=oX80OTBQLAyxQrJ7";

  return (
    <div>
      <NavBar />

      {/* session - 1 */}
      <div style={{ position: "relative" }}>
        <img
          className="home-image-1"
          alt=""
          src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/pilgramige1.jpg"
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.24)",
            zIndex: 0,
          }}
        ></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic">PILGRIMAGE</h1>
            <h2 className="m-topic-2">WASTE MANAGEMENT INITIATIVE</h2>
          </div>
        </div>
      </div>

      {/* session - 2 */}
      <div style={{ marginTop: "50px", marginBottom: "50px" }}>
        <center>
          <div className="page-content">
            <div className="table-container">
              <div className="table-row">
                <div className="table-column1">
                  <img
                    src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/oillamp-01-qgclgf9ubzf33sjqgaphcq0ec20chows5v68l83j7k.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      padding: "20px",
                    }}
                  />
                </div>
                <div className="table-column2">
                  <div style={{ marginTop: "73px", width: "80%" }}>
                    <h2 className="topic">
                      SRI LANKA HAS MANY RELIGIOUS EVENTS THAT SEE MILLIONS OF
                      PEOPLE GATHER THROUGHOUT THE YEAR
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      <div>
        <div className="pilgrimage-session-3">
          {/* // */}
          <div className="two-column-para">
            <div className="column-para column1-para">{/* Column 1 */}</div>
            <div className="column-para column2-para">
              {/* Column 2 */}

              <div className="pilgrimage-green-box">
                <h2 className="pilgrimage-green-box-t">
                  MILLIONS IN PLASTIC BOTTLE WASTE IS GENERATED FROM THESE
                  GATHERINGS
                </h2>
                <p className="pilgrimage-green-box-p">
                  With a lack of infrastructure and eco-education, Sri Lanka
                  often sees thousands of carelessly discarded bottles daily in
                  these events. This tarnishes the holy significance of our
                  religious sites and contributes to the global plastic waste
                  problem.
                </p>
              </div>
            </div>
          </div>
          {/* // */}
        </div>
      </div>

      {/* session - 3 */}
      <div
        className={`session5 ${session5InView ? "fadeInUp" : ""}`}
        ref={session5}
      >
        <center>
          <div className="page-content">
            <div className="table-container-s5">
              <div className="table-row-s5">
                <div className="table-column1-s5">
                  <img
                    src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/Untitled-2.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="table-column2-s5">
                  <h2 className="topic">
                    WE BUILT COLLECTION BINS <br />
                    IN ALL THESE LOCATIONS
                  </h2>
                  <p className="para">
                    After a survey of the issue, we realized that the root of
                    the problem stemmed from a lack of proper collection areas.
                    Without these, even the most eco-conscious person has no
                    place to put their waste. We fixed that by creating and
                    maintaining collection bins at each religious site.
                  </p>
                </div>
              </div>
            </div>
            <br />
            <center>
              <img
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/process_chart-01-1.jpg"
                alt=""
                style={{
                  width: "90%",
                  height: "auto",
                  objectFit: "cover",
                  marginBottom: "70px",
                }}
              />
            </center>
          </div>
        </center>
      </div>

      {/* session - 4 */}
      <div className="background-scroll-container">
        <div className="scroll-background-image-9"></div>
        <div className="content-sus-9">
          <center>
            <div className="w-bottom-box">
              <p className="topic">
                WASTE MANAGEMENT INITIATIVES TO PROTECT THE NATURAL BEAUTY AND
                SPIRITUALITY OF SRI LANKA
              </p>
            </div>
          </center>
        </div>
      </div>

      <center>
        <div className="para-box">
          {/* //// 01 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <img
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/8-annual-kataragama-waste-management-project.jpg"
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <div className="s4-a">
                  <h2 className="read-more-para-topic">
                    ECO SPINDLES CONCLUDES 8TH ANNUAL KATARAGAMA WASTE
                    MANAGEMENT PROJECT
                  </h2>
                  <h3 className="read-more-para-2nd-topic">August 20, 2022</h3>
                  <p style={isOpen1 ? null : paragraphStyles1}>
                    <p className="read-more-para">
                      Eco Spindles (Private) Limited, Sri Lanka’s largest
                      plastics recycler, recently concluded its 8th annual
                      Kataragama Waste Management Project by collecting 43,590
                      PET (polyethylene terephthalate) waste plastic bottles
                      discarded at the scared pilgrim sites of Ruhunu Kataragama
                      Maha Dewalaya, Sella Kataragama Temple, Wedihiti Kanda and
                      the surrounding areas
                    </p>
                    <p className="read-more-para">
                      The waste management project commenced on the 29th of July
                      and concluded on 12th of August, and engaged 60 volunteers
                      from the ‘Soba Parisara Thurunu Samjaya,’ a local youth
                      club in the area, 20 volunteers from Eco Spindles and its
                      project partners – the Kataragama Pradesiya Sabawa, and
                      the Ruhunu Kataragama Maha Dewalaya.
                    </p>
                    <p className="read-more-para">
                      Since starting the Kataragama Waste Management Project in
                      2014, Eco Spindles has collected 555,990 PET plastic
                      bottles, equivalent to 18,533 kilos to date. The project
                      aims to educate Sri Lankans about responsible recycling,
                      particularly those who visit Kataragama during the
                      festival.
                    </p>
                    <p className="read-more-para">
                      Further, Eco Spindles operates a network of collectors in
                      Kataragama, who play an integral part in collecting the
                      plastic that is discarded.The company continues to support
                      these entrepreneurs, who have based their livelihoods and
                      businesses on collecting plastics by buying the
                      accumulated plastic waste.
                    </p>
                    <p className="read-more-para">Read more –</p>
                    <p className="read-more-para">
                      <a href="http://bizenglish.adaderana.lk/eco-spindles-concludes-8th-annual-kataragama-waste-management-project/">
                        http://bizenglish.adaderana.lk/eco-spindles-concludes-8th-annual-kataragama-waste-management-project/
                      </a>
                    </p>
                    <p className="read-more-para">
                      <a href="http://biz.adaderana.lk/eco-spindles-8-%e0%b7%80%e0%b6%b1-%e0%b7%80%e0%b6%bb%e0%b6%a7%e0%b6%ad%e0%b7%8a-%e0%b6%b4%e0%b7%80%e0%b6%ad%e0%b7%8a%e0%b7%80%e0%b6%b1%e0%b7%94-%e0%b6%bd%e0%b7%90%e0%b6%b6%e0%b7%96-%e0%b6%9a%e0%b6%ad/">
                        Eco Spindles 8 වන වරටත් පවත්වනු ලැබූ කතරගම අපද්‍රව්‍ය
                        කළමනාකරණ ව්‍යාපෘතිය සාර්ථකව අවසන් කරයි – Adaderana Biz
                        Sinhala | Sri Lanka Business News
                      </a>
                    </p>

                    <br />
                  </p>
                  <center>
                    <div className="product-yarn-button-container">
                      <div className="read-more-bn-background">
                        <a
                          className="read-more-bn"
                          onClick={() => setIsOpen1(!isOpen1)}
                        >
                          {isOpen1 ? "Read less" : "Read more"}
                        </a>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <img
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/siripada.jpg"
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <div className="s4-a">
                  <h2 className="read-more-para-topic">
                    SIRI PADA (ADAM'S PEAK) WASTE MANAGEMENT PROJECT
                  </h2>
                  <h3 className="read-more-para-2nd-topic">
                    5371kg, of PET recycled in 2019
                  </h3>
                  <p style={isOpen2 ? null : paragraphStyles2}>
                    <p className="read-more-para">
                      Sri Pada is the most sacred and popular Sri Lankan
                      destination for citizens and tourists. Located within the
                      Peak Wilderness sanctuary – which provides a large amount
                      of endemic biodiversity – Sri Pada is the spring for many
                      major rivers in Sri Lanka.
                    </p>
                    <p className="read-more-para">
                      <span className="read-more-para-2nd-topic">
                        Project Partners:
                      </span>{" "}
                      Link Natural Product Private Ltd., the Maskeliya
                      Divisional Council, and the Nallathanniya WildLife
                      Department.
                    </p>

                    <br />
                  </p>
                  <center>
                    <div className="product-yarn-button-container">
                      <div className="read-more-bn-background">
                        <a
                          className="read-more-bn"
                          onClick={() => setIsOpen2(!isOpen2)}
                        >
                          {isOpen2 ? "Read less" : "Read more"}
                        </a>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
          {/* //// 02 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <img
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/katharagama-Project.jpg"
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <div className="s4-a">
                  <h2 className="read-more-para-topic">
                    KATARAGAMA WASTE MANAGEMENT PROJECT
                  </h2>
                  <h3 className="read-more-para-2nd-topic">
                    4275kg, of PET recycled in 2019
                  </h3>
                  <p style={isOpen3 ? null : paragraphStyles3}>
                    <p className="read-more-para">
                      Kataragama is a sacred destination for Sri Lankan and
                      Indian pilgrims, with peak season seeing thousands of
                      travelers. It is located within an environmentally vital
                      ecosystem including Menik River, Yala National Park, and
                      Lunugamvehera National Park.
                    </p>
                    <p className="read-more-para">
                      <span className="read-more-para-2nd-topic">
                        Project Partners:
                      </span>{" "}
                      The Kataragama Soba Mithuru Youth Club, the Kataragama
                      Divisional Council, the CEA, and Coca Cola Beverages
                      Private Ltd.
                    </p>

                    <br />
                  </p>
                  <center>
                    <div className="product-yarn-button-container">
                      <div className="read-more-bn-background">
                        <a
                          className="read-more-bn"
                          onClick={() => setIsOpen3(!isOpen3)}
                        >
                          {isOpen3 ? "Read less" : "Read more"}
                        </a>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <img
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/MUNNESWARAM-1.jpg"
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <h2 className="read-more-para-topic">
                  MUNNESWARAM WASTE MANAGEMENT PROJECT
                </h2>
                <h3 className="read-more-para-2nd-topic">
                  903kg, of PET recycled in 2019
                </h3>
                <p style={isOpen4 ? null : paragraphStyles4}>
                  <p className="read-more-para">
                    We started the Munneswaram project in 2019, and were able to
                    successfully start the recycling efforts in this temple.
                  </p>
                  <p className="read-more-para">
                    <span className="read-more-para-2nd-topic">
                      Project Partners:
                    </span>{" "}
                    Halawatha Medical Health Officer and Coca Cola Beverages
                    Private Ltd.
                  </p>

                  <br />
                </p>
                <center>
                  <div className="product-yarn-button-container">
                    <div className="read-more-bn-background">
                      <a
                        className="read-more-bn"
                        onClick={() => setIsOpen4(!isOpen4)}
                      >
                        {isOpen4 ? "Read less" : "Read more"}
                      </a>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
          {/* //// 03 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <img
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pilgrimage-waste-management/THALAWILA-1.jpg"
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <div className="s4-a">
                  <h2 className="read-more-para-topic">
                    THALAWILA WASTE MANAGEMENT PROJECT
                  </h2>
                  <h3 className="read-more-para-2nd-topic">
                    3702kg, of PET recycled in 2019
                  </h3>
                  <p style={isOpen5 ? null : paragraphStyles5}>
                    <p className="read-more-para">
                      Thalawila St. Anne’s Church is one of the most sacred
                      destinations for the Christian pilgrims. It is located in
                      the region of Kalpitiya coastal area which is an
                      attractive beach tourist destination related to the
                      Puttalam lagoon and Dutch bay. This region is also a
                      habitat for ocean mammals, mangroves, coral reefs, sea
                      grasses, resident and migrant bird species, and many more.
                    </p>
                    <p className="read-more-para">
                      <span className="read-more-para-2nd-topic">
                        Project Partners:
                      </span>{" "}
                      Puttalam Medical Health Office and Coca Cola Beverages
                      Private Ltd.
                    </p>

                    <br />
                  </p>
                  <center>
                    <div className="product-yarn-button-container">
                      <div className="read-more-bn-background">
                        <a
                          className="read-more-bn"
                          onClick={() => setIsOpen5(!isOpen5)}
                        >
                          {isOpen5 ? "Read less" : "Read more"}
                        </a>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                {/* <div className="s4-a"></div> */}
              </div>
            </div>
          </div>
        </div>
      </center>

      <br />
      <div>
        <ZoomSlider images={zoomImages} />
      </div>

      {/* session - 5 */}
      <div>
        <center>
          <div
            className={`pilgrimage-green-box-2 ${textInView ? "fadeInUp" : ""}`}
            ref={textboxRef}
          >
            <center>
              <div
                style={{
                  marginLeft: "10%",
                  marginRight: "10%",
                  marginTop: "10px",
                }}
              >
                <div className="topic-container">
                  <div className="bottom-box-horizontal-line bottom-box-left"></div>
                  <div>
                    <p className="bottom-box-topic-text">OUTCOME</p>
                  </div>
                  <div className="bottom-box-horizontal-line bottom-box-right"></div>
                </div>
                <p className="w-bottom-box-t">
                  20 TONNES OF PLASTIC BOTTLES RECYCLED ANNUALLY
                </p>
                <p className="w-bottom-box-p">
                  EVEN THOUGH THE COLLECTION NETWORK TOOK TIME AND EFFORT TO
                  <br />
                  MAINTAIN, WE ARE HAPPY TO KNOW THAT OUR EFFORTS BEAR FRUIT.
                  <br />
                  OUR HOLY SITES ARE CLEANER AND THE PLASTIC WASTE IS GIVEN A
                  SECOND
                  <br />
                  LIFE TO CONTRIBUTE FURTHER TO OUR SOCIETY.
                </p>
              </div>
            </center>
          </div>
        </center>
      </div>

      {/* session - 6 */}
      <div
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          maxWidth: "100%",
          overflow: "hidden",
          marginTop: "-50px",
          marginBottom: "50px",
        }}
      >
        <div>
          <PilgrimageSlider />
        </div>
      </div>

      {/* session - 7 */}
      <div style={{ marginTop: "100px", marginBottom: "100px" }}>
        <center>
          <YouTubeVideo videoUrl={videoUrl} />
        </center>
      </div>

      <Footer />
    </div>
  );
}
